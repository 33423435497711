import React from "react";
//import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import "./styles/main.css";
import "./styles/test.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import ReactGA from 'react-ga4';
//const TRACKING_ID = process.env.REACT_APP_TRACKING_ID;
//ReactGA.initialize(TRACKING_ID);

const TRACKING_ID = (process.env.NODE_ENV === "production")? process.env.REACT_APP_TRACKING_ID : process.env.REACT_APP_TRACKING_ID_DEV;
const TESTING = (process.env.REACT_APP_ANAL_TEST_MODE === ("true" || "True"))? true:false;
const GADEBUG = (process.env.REACT_APP_ANALYTICS_DEBUG === ("true"||"True"))? true:false;
const GTDEBUG = (process.env.REACT_APP_GTAG_DEBUG === ("true"||"True"))? true:false;

ReactGA.initialize([
  {
    trackingId: TRACKING_ID
  }
], {
  testMode: TESTING,
  gaOptions: {
    debug_mode: GADEBUG,
  },
  gtagOptions: {
    debug_mode: GTDEBUG,
  },
});

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

// After
// import { createRoot } from 'react-dom/client';
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
<React.StrictMode>
  <App />
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//reportWebVitals(console.log);
